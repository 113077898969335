export const mapeamentoAtos = {
  5146: ['temEnvolvidos'],
  5148: ['temEnvolvidos'],
  5161: ['temEnvolvidosAtos'],
  5162: ['temEnvolvidosAtos'],
  5163: ['temEnvolvidosAtos'],
  5164: ['temEnvolvidosAtos'],
  5108: ['temOutorgantesOutorgados', 'temFatorAcrescimo'],
  5109: ['temOutorgantesOutorgados', 'temFatorAcrescimo'],
  5110: ['temOutorgantesOutorgados'],
  5111: ['temOutorgantesOutorgados'],
  5138: ['temKm'],
  5139: ['temKm'],
  5150: ['temMinutos'],
  5087: ['temMetroQuadrado'],
  5088: ['temHectare'],
  5114: ['temUnidadesAutonomas', 'temFatorReducao'],
  5147: ['temUnidadesAutonomas'],
  5048: ['temKBytes'],
  5112: ['temFatorReducao'],
  5113: ['temFatorReducao'],
  5115: ['temFatorReducao'],
  5069: ['temFatorReducao'],
  5071: ['temFatorReducao'],
}
