import { mapeamentoAtos } from '~/types/Orcamento/const/MapeamentoAtos'
import type OrcamentosLista from '~/types/Orcamento/OrcamentosLista'
import type Orcamento from '~/types/Orcamento'
import type Servico from '~/types/Servico'
import type Preposto from '~/types/Preposto'
import type FormNovoOrcamento from '~/types/Orcamento/FormNovoOrcamento'
import type NovoCliente from '~/types/Orcamento/NovoCliente'
import type AtoParaCalcular from '~/types/Orcamento/AtoParaCalcular'
import type Pessoa from '~/types/Pessoa'
import type Pagamento from '~/types/Pagamento'

export const useOrcamentoStore = defineStore('orcamentoStore', {
  state: () => ({
    orcamentos: [] as OrcamentosLista[],
    pessoas: [] as Pessoa[],
    prepostos: [] as Preposto[],
    novoOrcamento: {} as Orcamento,
    editMode: false,
    filtros: {
      ano: new Date().getFullYear(),
      mes: new Date().getMonth() + 1,
      tipo: 'executado',
      termoDeBusca: '',
    },
    formNovoOrcamento: {
      cpf_cnpj: '',
      descricao: '',
      horario_requisicao: new Date(),
      nome: '',
      observacao: '',
      observacao_rodape: '',
      telefone: '',
      pessoa_id: '',
      servicos: {
        EMOLUMENTOS: [] as AtoParaCalcular[],
        EXTRA: [] as Servico[],
      },
    } as FormNovoOrcamento,
    orcamento: {} as Orcamento,
    totalDePaginas: 1,
    paginaAtual: 1,
    itensPorPagina: [20], // CFormSelect
    quantidadeDePaginasParaExibir: 2,
    prepostoParaFiltrar: 'TODOS',
    tiposDeRecolhimento: [
      { NENHUM: 'NÃO RECOLHIDO' },
      { TOTAL: 'RECOLHIDO' },
      { PARCIAL: 'RECOLHIDO PARCIAL' },
      { MAIOR: 'RECOLHIDO MAIOR' }
    ],
    tiposDeAtos: [
      'NOTAS',
      'IMOVEIS',
      'PROTESTO',
      'RTD',
      'RCPJ',
      'RCPN'
    ],
    formasDePagamento: [
      { value: 'DINHEIRO', label: 'Dinheiro' },
      { value: 'CARTAO_DE_CREDITO', label: 'Cartão de Crédito' },
      { value: 'PIX', label: 'PIX' },
      { value: 'CARTAO_DE_DEBITO', label: 'Cartão de Débito' },
      { value: 'DEPOSITO_CONTA', label: 'Transf./Depósito' },
      { value: 'CHEQUE', label: 'Cheque' },
      { value: 'NOTA_PROMISSORIA', label: 'Nota Promissória' },
      { value: 'DEPOSITO_SERVICO_ELETRONICO', label: 'Creditado pela Central de Serv. Eletrônicos' }
      // OUTROS: 'Outros',
    ],
    recolhimentoParaFiltrar: 'TODOS',
    termoDeBusca: '',
    modalImportarClienteDeOutroCartorio: {
      exibir: false,
      listaDeUsuarios: [],
    },
    modalAdicionarAto: {
      exibir: false,
      listaDeAtos: [],
      atoSelecionado: {
        codigo: 0,
        descricao: '',
        apelido: '',
        servico_id: '',
        nome: '',
        tipo: 'TODOS',
        temValor: false,
        temPaginas: false,
        temEnvolvidos: false,
        temEnvolvidosAtos: false,
        temOutorgantesOutorgados: false,
        temKm: false,
        temMinutos: false,
        temMetroQuadrado: false,
        temHectare: false,
        temUnidadesAutonomas: false,
        temKBytes: false,
        quantidade_envolvidos: null,
        valor_ato: 0,
        valor: 0,
        quantidade: 1,
        temFatorAcrescimo: false,
        temFatorReducao: false,
        iss: 0,
        tfj: 0,
        funcivil: 0,
        fator: 0,
        quantidadeDeAtos: 1,
        hectare: '',
        horario_requisicao: '',
      } as AtoParaCalcular,
      atoSelecionadoId: '',
      atoSelecionadoParaEditar: {},
      editMode: false,
      tipoDeAto: 'TODOS',
      camposExtra: [],
    },
    listaDeCamposExtra: ['valor_economico', 'valor_fiscal', 'valor_avaliacao', 'quantidade_paginas', 'fator'],
    modalCadastrarCliente: {
      exibir: false,
      novoCliente: {
        cnpj: '',
        cpf: '',
        cpf_cnpj: '',
        email_comercial: '',
        horario_requisicao: '',
        nome: '',
        razao_social: '',
        sem_cpf_cnpj: '',
        tipo: '',
        tipo_pessoa: '',
        tipo_sem_cpf_cnpj: '',
        whatsapp: '',
        whatsapp_ddi: '',
      } as NovoCliente,
    },
    modalAdicionarAtoExtra: {
      exibir: false,
      listaDeAtosExtra: [],
      atoExtraSelecionado: '',
      indexAtoExtraParaEditar: -1,
      quantidade: 1,
      editMode: false,
    },
    modalImprimir: {
      exibir: false,
      orcamentoId: '',
      requerimento: false,
    },
    colunasServicos: [
      { key: 'nome', label: 'Item da Tabela', format: 'string' },
      { key: 'nome', label: 'Descrição do serviço', format: 'string' },
      { key: 'valor_ato', label: 'Base de cálculo', format: 'currency' },
      { key: 'valor_cartorio', label: 'Valor dos emolumentos', format: 'currency' },
      { key: 'valor_tribunal', label: 'Tribunal (TFJ e FUNCIVIL)', format: 'currency' },
      { key: 'valor', label: 'Subtotal', format: 'currency' }
    ],
    colunasServicosExtra: [
      { key: 'nome', label: 'Item da Tabela', format: 'string' },
      { key: 'valor', label: 'Base de cálculo', format: 'currency' },
      { key: 'valor_cartorio', label: 'Valor dos emolumentos', format: 'currency' }
    ],
  }),
  actions: {
    async fetchOrcamentos () {
      let url = '/gerencia/orcamento?'
      const params = [
        `page=${this.paginaAtual}`,
        `id=${this.prepostoParaFiltrar}`,
        `recolhido=${this.recolhimentoParaFiltrar}`,
        `limite=${this.itensPorPagina}`,
        `busca=${this.termoDeBusca}`
      ]
      url += params.join('&')

      const orcamentosParaExibir = await useApiStore().request(url)
      this.itensPorPagina = orcamentosParaExibir.per_page
      this.totalDePaginas = orcamentosParaExibir.last_page
      this.paginaAtual = orcamentosParaExibir.current_page
      const orcamentos = this.prepararModeloOrcamentos(orcamentosParaExibir.data)
      this.orcamentos = orcamentos
    },
    async fetchOrcamentosGraficoDoughnut () {
      const url = '/gerencia/orcamento?page=1&id=TODOS&recolhido=TODOS&limite=1500&busca='
      const orcamentos = await useApiStore().request(url)
      return orcamentos.data
    },
    prepararModeloOrcamentos (orcamentos: Orcamento[]): OrcamentosLista[] {
      return orcamentos.map((orcamento) => {
        const valorDosServicos = orcamento.servicos?.reduce((acc, servico: Servico) => {
          return { valor: acc.valor + (parseFloat(servico.valor) * servico.quantidade) }
        }, { valor: 0 })
        return {
          id: orcamento.id,
          numero: orcamento.numero,
          created_at: useStore().formatarDateTime(orcamento.created_at),
          preposto: orcamento.usuario?.pessoa?.especializacao.nome,
          apresentante: orcamento.pessoa?.especializacao?.nome ?? orcamento.pessoa?.especializacao?.razao_social,
          valor: useStore().formatarDinheiro(valorDosServicos?.valor, true) as unknown as number,
          recolhido: orcamento.recolhido,
          servicos: orcamento.servicos,
          cartorio: orcamento.cartorio,
          pagamentos: orcamento.pagamentos?.map((pagamento) => {
            if (pagamento.forma) {
              return {
                ...pagamento,
                formaPagamento: pagamento.forma,
              }
            }
            return pagamento
          }),
        }
      })
    },
    async fetchPrepostos () {
      const prepostos = await useApiStore().request('/gerencia/usuario')
      this.prepostos = prepostos.data.map((preposto: Preposto) => {
        return {
          label: preposto.nome,
          value: preposto.id,
          status: preposto.status,
        }
      })
    },
    async verificarSePessoaExiste (documento: string) {
      try {
        const pessoa = await useApiStore().request(`/gerencia/pessoa/existe/${documento}`)
        return pessoa
      } catch (err) {
        if (err.response.status === 404) {
          return false
        }
      }
    },
    async verificarSePessoaExisteEmOutroCartorio (documento: string) {
      try {
        const pessoas = await useApiStore().request(`/gerencia/pessoa/existe-outros-cartorios/${documento}`)
        if (pessoas && pessoas.length === 0) {
          return false
        }
        this.modalImportarClienteDeOutroCartorio.listaDeUsuarios = pessoas
        return true
      } catch (err) {
        if (err.response.status === 404) {
          return false
        }
      }
    },
    preencherFormNovoOrcamento (pessoa: Pessoa) {
      this.formNovoOrcamento.cpf_cnpj = pessoa.cpf ?? pessoa.cnpj
      this.formNovoOrcamento.nome = pessoa.nome ?? pessoa.razao_social
      this.formNovoOrcamento.telefone = pessoa.whatsapp ?? (pessoa.telefone_celular ?? pessoa.telefone_fixo)
      this.formNovoOrcamento.pessoa_id = pessoa.id
    },
    async fetchAtos (tipo?: string = 'TODOS') {
      try {
        const now = new Date()
        let baseUrl = `/administracao/emolumentos/${tipo}/`

        const dataDeHoje = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`
        baseUrl += dataDeHoje
        this.modalAdicionarAto.listaDeAtos = await useApiStore().request(baseUrl)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchAtosExtra () {
      const atosExtra = await useApiStore().request('/gerencia/servico')
      this.modalAdicionarAtoExtra.listaDeAtosExtra = atosExtra
    },
    async calcularValorDoAto (ato?: AtoParaCalcular) {
      const calculo = await useApiStore().request('/financeiro/orcamento/calcular', {
        method: 'POST',
        body: ato ?? this.modalAdicionarAto.atoSelecionado,
      })
      return calculo[0]
    },
    async adicionarAto () {
      const novoAto = this.prepararModeloNovoAto(this.modalAdicionarAto.atoSelecionado)
      const calculo = await this.calcularValorDoAto(novoAto)
      const valor = calculo.total
      const subtotal = valor * this.modalAdicionarAto.atoSelecionado.quantidade
      const emolumento = {
        ...novoAto,
        valor,
        subtotal,
        fundo_eletronizacao: calculo.fundo_eletronizacao,
        iss: calculo.iss,
        funcivil: calculo.funcivil,
        tfj: calculo.tfj,
        nome: novoAto.nome,
      }
      if (this.modalAdicionarAto.editMode) {
        if (this.editMode) {
          this.formNovoOrcamento.alterados.EMOLUMENTOS = [...this.formNovoOrcamento.alterados.EMOLUMENTOS, this.modalAdicionarAto.atoSelecionadoParaEditar.id]
        }
        this.formNovoOrcamento.servicos.EMOLUMENTOS = this.formNovoOrcamento.servicos.EMOLUMENTOS.map((ato) => {
          if (ato.servico_id === this.modalAdicionarAto.atoSelecionadoParaEditar.servico_id) {
            return emolumento
          }
          return ato
        })
        this.modalAdicionarAto.editMode = false
        this.modalAdicionarAto.exibir = false
      } else {
        this.formNovoOrcamento.servicos.EMOLUMENTOS = [...this.formNovoOrcamento.servicos.EMOLUMENTOS, emolumento]
      }

      this.modalAdicionarAto.tipoDeAto = 'TODOS'
      this.modalAdicionarAto.atoSelecionadoId = ''
    },
    resetarAtoSelecionado () {
      this.modalAdicionarAto.atoSelecionado = {
        codigo: 0,
        descricao: '',
        apelido: '',
        servico_id: '',
        nome: '',
        tipo: 'TODOS',
        temValor: false,
        temPaginas: false,
        temEnvolvidos: false,
        temEnvolvidosAtos: false,
        temOutorgantesOutorgados: false,
        temKm: false,
        temMinutos: false,
        temMetroQuadrado: false,
        temHectare: false,
        temUnidadesAutonomas: false,
        temKBytes: false,
        quantidade_envolvidos: null,
        valor_ato: 0,
        valor: 0,
        quantidade: 1,
        temFatorAcrescimo: false,
        temFatorReducao: false,
        iss: 0,
        tfj: 0,
        funcivil: 0,
        fator: 0,
        quantidadeDeAtos: 1,
        hectare: '',
        horario_requisicao: '',
      }
    },
    cancelarAdicaoDeAto () {
      this.modalAdicionarAto.exibir = false
      this.modalAdicionarAto.tipoDeAto = 'TODOS'
      this.resetarAtoSelecionado()
      this.modalAdicionarAto.editMode = false
    },
    adicionarAtoExtra (novoAtoExtra: Servico, quantidade: number = 1) {
      const cartorio = useUsuarioStore().usuarioAtual.cartorio
      const valor = parseFloat(novoAtoExtra.valor)
      const iss = (Math.ceil((valor + cartorio.iss * valor) * 100) / 100) - valor
      const ato = {
        iss,
        nome: novoAtoExtra.nome,
        quantidade,
        servico_id: novoAtoExtra.id,
        valor,
        // valor: valor + iss,
      }

      if (this.modalAdicionarAtoExtra.editMode) {
        this.formNovoOrcamento.servicos.EXTRA[this.modalAdicionarAtoExtra.indexAtoExtraParaEditar] = ato
        this.modalAdicionarAtoExtra.editMode = false
        this.modalAdicionarAtoExtra.exibir = false
      } else {
        this.formNovoOrcamento.servicos.EXTRA = [...this.formNovoOrcamento.servicos.EXTRA, ato]
      }
    },
    async salvarNovoOrcamento () {
      const orcamento = await useApiStore().request('/gerencia/orcamento', {
        method: 'POST',
        body: this.formNovoOrcamento,
      })
      if (!orcamento) {
        throw new Error('Erro ao salvar orçamento')
      }
      return orcamento
    },
    async atualizarOrcamento () {
      delete this.formNovoOrcamento.servicos_atos
      delete this.formNovoOrcamento.servicos_extras
      delete this.formNovoOrcamento.edicoes
      delete this.formNovoOrcamento.total_cartorio
      delete this.formNovoOrcamento.total_tribunal

      // this.formNovoOrcamento.baixado = 0
      // horario_requisicao in format 'YYYY-mm-dd HH:MM:SS'
      this.formNovoOrcamento.horario_requisicao = new Date().toISOString().split('T')[0] + ' ' + new Date().toLocaleTimeString()
      this.formNovoOrcamento.classe = 'App\\Models\\Entities\\Orcamento'

      const orcamento = await useApiStore().request(`/gerencia/orcamento/${this.orcamento.id}`, {
        method: 'PUT',
        body: this.formNovoOrcamento,
      })
      if (!orcamento) {
        throw new Error('Erro ao atualizar orçamento')
      }
      return orcamento
    },
    formatarNumeroDoOrcamento (orcamento: Orcamento, completarComZero: boolean = true) {
      if (!orcamento) {
        return ''
      }
      const numeroFormatado = completarComZero ? orcamento.numero?.toString().padStart(6, '0') : orcamento.numero
      const ano = orcamento.ano ?? orcamento.created_at?.split('/')[2].split(',')[0]
      return `${numeroFormatado}/${ano}`
    },
    async fetchOrcamentoPorId (id: string) {
      const orcamento = await useApiStore().request(`/gerencia/orcamento/${id}/completo`)
      return orcamento
    },
    obterNomeItemDaTabela (nome: string) {
      if (!nome) {
        return ''
      }
      return nome.split('-')[0]
    },
    async fetchCamposEmolumento (emolumentoId: string) {
      if (!emolumentoId) {
        return []
      }
      const camposPersonalizados = await useApiStore().request(`/gise/emolumento/campos/${emolumentoId}`) as []
      return camposPersonalizados?.campos
    },
    removerServico (servico: AtoParaCalcular) {
      this.formNovoOrcamento.alterados.EMOLUMENTOS = [...this.formNovoOrcamento.alterados.EMOLUMENTOS, servico.id]
      this.formNovoOrcamento.servicos.EMOLUMENTOS = this.formNovoOrcamento.servicos.EMOLUMENTOS.filter((ato) => {
        return ato.id !== servico.id
      })
    },
    async editarServico (servico: AtoParaCalcular) {
      this.modalAdicionarAto.atoSelecionadoParaEditar = servico
      const tipo = this.formNovoOrcamento.servicos_atos?.find(ato => ato.servico_id === servico.servico_id).emolumento.tipo ?? 'TODOS'
      this.modalAdicionarAto.tipoDeAto = (tipo === 'COMUM' ? 'TODOS' : tipo) ?? 'TODOS'
      this.modalAdicionarAto.atoSelecionadoId = servico.servico_id
      this.modalAdicionarAto.atoSelecionado = Object.assign({}, servico)
      this.modalAdicionarAto.atoSelecionado.nome_ato = servico.nome
      if (this.modalAdicionarAto.listaDeAtos.length === 0) {
        await this.fetchAtos(servico.tipo)
      }
      this.modalAdicionarAto.camposExtra = await this.fetchCamposEmolumento(servico.servico_id)
      this.modalAdicionarAto.exibir = true
      this.modalAdicionarAto.editMode = true
    },
    removerServicoExtra (servico: Servico) {
      if (this.editMode) {
        this.formNovoOrcamento.alterados.EXTRA = [...this.formNovoOrcamento.alterados.EXTRA, servico.id]
      }
      this.formNovoOrcamento.servicos.EXTRA = this.formNovoOrcamento.servicos.EXTRA.filter((ato) => {
        return ato !== servico
      })
    },
    editarServicoExtra (index: number) {
      const livroDiarioAuxiliarStore = useLivroDiarioAuxiliarStore()
      livroDiarioAuxiliarStore.modalAdicionarReceita.exibir = true
      livroDiarioAuxiliarStore.modalAdicionarReceita.receitaSelecionada = this.formNovoOrcamento.servicos.EXTRA[index].servico_id
      livroDiarioAuxiliarStore.modalAdicionarReceita.quantidade = this.formNovoOrcamento.servicos.EXTRA[index].quantidade
      livroDiarioAuxiliarStore.modalAdicionarReceita.valor = this.formNovoOrcamento.servicos.EXTRA[index].valor
      livroDiarioAuxiliarStore.modalAdicionarReceita.editMode = true
    },
    prepararModeloNovoAto (ato: AtoParaCalcular) {
      if (!ato) {
        ato = this.modalAdicionarAto.listaDeAtos.find((ato) => {
          return ato.id === this.modalAdicionarAto.atoSelecionadoId
        })
      }
      const novoAto = {} as AtoParaCalcular
      novoAto.tipo = this.modalAdicionarAto.tipoDeAto
      novoAto.nome = ato.nome_ato ?? ato.nome
      novoAto.apelido = ato.nome_ato ?? ato.nome
      novoAto.descricao = ato.nome_ato ?? ato.nome
      novoAto.servico_id = ato.emolumento?.id ?? ato.servico_id ?? ato.id
      novoAto.codigo = ato.codigo
      novoAto.temEnvolvidos = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temEnvolvidos')) === true
      novoAto.temEnvolvidosAtos = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temEnvolvidosAtos')) === true
      novoAto.temFatorAcrescimo = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temFatorAcrescimo')) === true
      novoAto.temFatorReducao = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temFatorReducao')) === true
      novoAto.temHectare = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temHectare')) === true
      novoAto.temKBytes = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temKBytes')) === true
      novoAto.temKm = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temKm')) === true
      novoAto.temMetroQuadrado = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temMetroQuadrado')) === true
      novoAto.temMinutos = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temMinutos')) === true
      novoAto.temOutorgantesOutorgados = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temOutorgantesOutorgados')) === true
      novoAto.temPaginas = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temPaginas')) === true
      novoAto.temUnidadesAutonomas = (mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo]?.includes('temUnidadesAutonomas')) === true
      novoAto.valor = ato.total
      novoAto.temValor = ato.valor_ato > 0
      novoAto.valor_ato = ato.valor_ato
      novoAto.quantidade = parseInt(ato.quantidadeDeAtos) ?? parseInt(ato.quantidade)
      novoAto.quantidadeDeAtos = parseInt(ato.quantidadeDeAtos) ?? parseInt(ato.quantidade)
      novoAto.subtotal = novoAto.valor * novoAto.quantidade
      novoAto.fator = novoAto.fator ?? 0
      novoAto.funcivil = parseInt(ato.funcivil ?? 0)
      novoAto.iss = parseInt(ato.iss ?? 0)
      novoAto.horario_requisicao = ato.horario_requisicao ?? new Date().toISOString().split('T')[0] + ' ' + new Date().toLocaleTimeString()
      novoAto.quantidade_envolvidos = ato.quantidade_envolvidos ?? null
      novoAto.quantidade_paginas = ato.quantidade_paginas ?? null
      if (novoAto.temUnidadesAutonomas) {
        novoAto.quantidade_unidade_autonomas = ato.quantidade_unidade_autonomas
      }
      if (novoAto.temHectare) {
        novoAto.hectare = ato.hectare
      }
      novoAto.tfj = ato.tfj ?? 0
      // novoAto.hectare = ato.hectare ?? null
      if (ato.emolumento) {
        novoAto.atoSelecionado = {
          ...ato.emolumento ?? ato,
          id: novoAto.id,
        }
      }
      return novoAto
    },
    async removerPagamentoDoOrcamento (pagamentoParaApagar) {
      try {
        this.orcamento.pagamentos = this.orcamento.pagamentos.filter((pagamento) => {
          if ((pagamento.dataBase === pagamentoParaApagar.dataBase) && (pagamento.formaPagamento === pagamentoParaApagar.formaPagamento) && (pagamento.valor === pagamentoParaApagar.valor) && (pagamento.quantidadeParcelas === pagamentoParaApagar.quantidadeParcelas) && (pagamento.prazoPagamento === pagamentoParaApagar.prazoPagamento)) {
            return false
          }
          return true
        })
        const pagamentoData = this.prepararModeloPagamento()
        await useApiStore().request(`/gerencia/orcamento/pagar/${this.orcamento.id}`, {
          method: 'POST',
          body: pagamentoData,
        })
        return true
      } catch (err) {
        return err
      }
    },
    async adicionarPagamentoAoOrcamento (pagamento: any) {
      try {
        let novoPagamentoData
        if (Array.isArray(pagamento)) {
          novoPagamentoData = pagamento
        } else {
          novoPagamentoData = [this.prepararModeloNovoPagamento(pagamento)]
        }
        this.orcamento.pagamentos = [...this.orcamento.pagamentos, ...novoPagamentoData]
        if (!this.orcamento.id) {
          return
        }
        const pagamentoData = this.prepararModeloPagamento(novoPagamentoData)
        const orcamento = await useApiStore().request(`/gerencia/orcamento/pagar/${this.orcamento.id}`, {
          method: 'POST',
          body: pagamentoData,
        })
        return orcamento
      } catch (err) {
        return err
      }
    },
    prepararModeloPagamento () {
      const formasDePagamento = this.orcamento.pagamentos.map((pagamento) => {
        return {
          formaPagamento: pagamento.formaPagamento,
          valor: pagamento.valor,
          quantidadeParcelas: pagamento.quantidadeParcelas,
          prazoPagamento: pagamento.prazoPagamento,
          dataBase: pagamento.dataBase,
        }
      })
      return {
        id: this.orcamento.id,
        formas: formasDePagamento,
        horario_requisicao: new Date().toISOString().split('T')[0] + ' ' + new Date().toLocaleTimeString(),
        numero: this.formatarNumeroDoOrcamento(this.orcamento, false),
        original: this.orcamento,
      }
    },
    prepararModeloNovoPagamento ({ formaDePagamento, valor, quantidadeParcelas, prazoPagamento, dataBase }) {
      return {
        formaPagamento: formaDePagamento,
        valor,
        quantidadeParcelas: prazoPagamento === 'A_VISTA' ? 1 : quantidadeParcelas,
        prazoPagamento,
        dataBase,
      }
    },
    statusRecolhimento (orcamento: Orcamento) {
      let quantidade = 0
      let quantidadeBaixada = 0
      if (orcamento.servicos?.length > 1) {
        quantidade = orcamento.servicos?.reduce((acc, servico) => {
          return (acc.quantidade ?? acc) + servico.quantidade
        })
        quantidadeBaixada = orcamento.servicos?.reduce((acc, servico) => {
          return (acc.quantidade_baixada ?? acc) + servico.quantidade_baixada
        })
      } else if (orcamento.servicos?.length === 1) {
        quantidade = orcamento.servicos[0].quantidade
        quantidadeBaixada = orcamento.servicos[0].quantidade_baixada
      }
      if (quantidadeBaixada > 0 && quantidade === quantidadeBaixada) {
        return 'EXECUTADO'
      } else if (quantidadeBaixada < quantidade && quantidadeBaixada > 0) {
        return 'EXECUTANDO'
      }
      return 'NÃO EXECUTADO'
    },
    async fetchDepositosPrevios () {
      const mainStore = useStore()

      // Ensure we have valid filtros
      if (!this.filtros.ano || !this.filtros.mes) {
        this.filtros = {
          ...this.filtros,
          ano: mainStore.competencia.ano,
          mes: mainStore.competencia.mes,
          tipo: 'deposito',
        }
      }

      let url = `/gerencia/livro-diario-auxiliar/transacoes/${this.filtros.ano}/${this.filtros.mes}/${this.filtros.tipo}?`
      const params = [
        `page=${this.paginaAtual}`,
        `limite=${this.itensPorPagina}`,
        this.filtros.termoDeBusca ? `busca=${this.filtros.termoDeBusca}` : ''
      ].filter(Boolean)

      url += params.join('&')

      const response = await useApiStore().request(url)
      if (response) {
        this.pessoas = response.data || []
        this.totalDePaginas = response.last_page
        this.paginaAtual = response.current_page
        this.itensPorPagina = response.per_page
      }
    },
    async mudarPagina (pagina: number) {
      if (pagina >= 1 && pagina <= this.totalDePaginas) {
        useStore().loading = true
        this.paginaAtual = pagina
        await this.fetchOrcamentos()
        useStore().loading = false
      }
    },
    retornarQuantidadeDeParcelas (pagamento: Pagamento) {
      return pagamento.children ? pagamento.children.length + 1 : 1
    },
    somarValorDasParcelas (pagamento) {
      let valorDaParcela = 0
      if (pagamento.children) {
        valorDaParcela = pagamento.children.reduce((acc, parcela) => acc + parseFloat(parcela.valor), 0)
      }
      return valorDaParcela + parseFloat(pagamento.valor)
    },
  },
  getters: {
    getPrepostosAtivos: (state) => {
      return state.prepostos.filter((preposto: Preposto) => {
        const bloqueado = preposto.status?.toUpperCase() !== 'BLOQUEADO'
        return bloqueado
      })
    },
    getOpcoesDeRecolhimento: (state) => {
      const recolhimentos = state.tiposDeRecolhimento.map((recolhimento) => {
        return {
          label: Object.values(recolhimento)[0],
          value: Object.keys(recolhimento)[0],
        }
      })
      return recolhimentos
    },
    getTotalNovoOrcamento: (state) => {
      const total = state.formNovoOrcamento.servicos.EMOLUMENTOS.reduce((acc, ato) => {
        return acc + ato.valor * ato.quantidade
      }, 0) ?? 0
      const totalExtra = state.formNovoOrcamento.servicos.EXTRA.reduce((acc, ato) => {
        return acc + ato.valor * ato.quantidade
      }, 0) ?? 0
      return total + totalExtra
    },
    getTotalServicosOrcamento: () => (orcamento: any) => {
      if (!orcamento.servicos?.length || orcamento.servicos?.length === 0) {
        return 0
      }
      const total = orcamento.servicos?.reduce((acc, ato) => {
        return acc + ato.valor * ato.quantidade
      }, 0) ?? 0
      return total
    },
    getTotalServicosExtra: () => (orcamento: any) => {
      if (!orcamento.extras) {
        return 0
      }
      const total = orcamento.extras?.reduce((acc, ato) => {
        return acc + ato.valor * ato.quantidade
      }, 0) ?? 0
      return total
    },
    getTotalDeTaxasDoTribunal: () => (orcamento: any) => {
      if (!orcamento.servicos?.length || orcamento.servicos?.length === 0) {
        return 0
      }
      const total = orcamento.servicos?.reduce((acc, ato) => {
        return acc + ato.valor_tribunal
      }, 0) ?? 0
      return total
    },
    getTotalGeral: state => (orcamento: any) => {
      return state.getTotalServicosOrcamento(orcamento) + state.getTotalServicosExtra(orcamento)
    },
    getPagamentosComParcelas: (state) => {
      let pagamentosAgrupados = []

      for (let i = 0; i < state.orcamento.pagamentos?.length; i++) {
        const pagamento = state.orcamento.pagamentos[i]
        const formaDePagamento = pagamento.formaPagamento ?? pagamento.forma
        const quantidadeDeParcelas = pagamento.quantidadeParcelas ?? pagamento.parcelas ?? 1
        if (formaDePagamento !== 'CARTAO_DE_CREDITO') {
          pagamentosAgrupados[i] = pagamento
        } else if (quantidadeDeParcelas === 1) {
          pagamentosAgrupados[i] = { ...pagamento, children: [] }
          let j = i
          for (j; j < state.orcamento.pagamentos?.length; j++) {
            const proximoPagamento = state.orcamento.pagamentos[j]
            if (([proximoPagamento.forma, proximoPagamento.formaPagamento].includes('CARTAO_DE_CREDITO') && (proximoPagamento.quantidadeParcelas ?? proximoPagamento.parcelas) > 1)) {
              pagamentosAgrupados[i].children.push(proximoPagamento)
              if ((state.orcamento.pagamentos[j + 1]?.quantidadeParcelas ?? state.orcamento.pagamentos[j + 1]?.parcelas) === 1) {
                i = j
                break
              }
            }
          }
        }
      }
      pagamentosAgrupados = pagamentosAgrupados.filter((pagamento) => {
        return pagamento !== undefined
      })
      return pagamentosAgrupados
    },
  },
  persist: {
    paths: [],
  },
})
